const data = ({cryptoLendRate, cryptoLendRateStable, minTermRate, maxTermRate}) => [
  {
    benefit: 'Suitable for',
    flex: 'Novice investors, new customers, and those who need access to their money',
    cryptoBacked: 'Experienced investors who can commit to a fixed term for a better rate',
    cryptoLend: 'Long-term cryptocurrency holders who want to earn a return on their idle assets',
    lendingLottery: 'Anyone from novice to experienced investors who want a shot at winning cash prizes—up to 10 million USD',
    // loanOriginator: 'Skilled, long-term investors who want the best return across multiple investments',
    cryptoBackedLoan: 'Crypto holders who want cash or new coins without selling their existing assets',
  },
  {
    benefit: 'Interest rate',
    flex: '4% APY',
    cryptoBacked: `${minTermRate} - ${maxTermRate}% APR`,
    cryptoLend: `${cryptoLendRate} - ${cryptoLendRateStable}% APY`,
    lendingLottery: `1% APR`,
    // loanOriginator: '9.5-12% APY',
    cryptoBackedLoan: `From ${minTermRate}% APR`,
  },
  {
    benefit: 'Interest paid',
    flex: 'Every second',
    cryptoBacked: 'At the end of the term',
    cryptoLend: 'Every second',
    lendingLottery: 'At the end of the term',
    // loanOriginator: 'Monthly or at the end of the term',
    cryptoBackedLoan: 'N/A',
  },
  {
    benefit: 'Term',
    flex: 'No term',
    cryptoBacked: `
        1 month<br/>3 months<br/>6 months<br/>9 months
    `,
    cryptoLend: 'No term',
    lendingLottery: '1 month',
    // loanOriginator: '6-15 months',
    cryptoBackedLoan: `
        1 month<br/>3 months<br/>6 months<br/>9 months
    `,
  },
  {
    benefit: 'Withdrawal',
    flex: 'Anytime',
    cryptoBacked: 'At the end of the term',
    cryptoLend: 'Anytime',
    lendingLottery: 'At the end of the term',
    // loanOriginator: 'At the end of the term',
    cryptoBackedLoan: 'Loan is available immediately. Collteral returned at the end of the term',
  },
  {
    benefit: 'Protection',
    flex: 'Collateral put up in a third-party lending pool',
    cryptoBacked: 'Collateral put up by MyConstant borrowers',
    cryptoLend: 'MyConstant will indemnify you in certain cases',
    lendingLottery: 'Investments are covered against loss or theft while in the custody of third parties by the MyConstant guarantee—a 10 million USD discretionary fund',
    // loanOriginator: 'Loan originator’s buy-back guarantee',
    cryptoBackedLoan: 'Collateral stored in Prime Trust cold wallet or password-protected web wallet',
  },
  {
    benefit: 'Minimum',
    flex: '10 USD',
    cryptoBacked: '50 USD',
    cryptoLend: '0.01 BNB, 0.005 BTC, 0.1 ETH',
    lendingLottery: '100 USD',
    // loanOriginator: '50 USD',
    cryptoBackedLoan: '50 USD',
  },
  {
    benefit: 'Maximum',
    flex: 'No',
    cryptoBacked: 'No',
    cryptoLend: '50 BNB, 0.5 BTC, 5 ETH',
    lendingLottery: 'No',
    // loanOriginator: 'No',
    cryptoBackedLoan: 'No',
  },
  {
    benefit: 'End term early?',
    flex: 'N/A',
    cryptoBacked: 'Yes, and you earn 2% APR  on elapsed time',
    cryptoLend: 'N/A',
    lendingLottery: 'Anytime, but you’ll lose tickets, winnings, and interest earned',
    // loanOriginator: 'Yes',
    cryptoBackedLoan: 'Yes (no penalties)',
  },
  {
    benefit: 'Supported assets',
    flex: 'Fiat (USD and others) and stablecoins',
    cryptoBacked: 'Fiat (USD and others) and stablecoins',
    cryptoLend: 'We support 10+ cryptocurrencies',
    lendingLottery: 'Fiat (USD)',
    // loanOriginator: 'Fiat (USD and others) only',
    cryptoBackedLoan: 'We support 70+ cryptocurrencies',
  },
];

export default data;
