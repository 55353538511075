import React from 'react';
import LabelLang from 'src/lang/components/LabelLang';
import styled from 'styled-components';
import cryptos from './img/cryptos.svg';

const CryptoStyled = styled.div`
  img {
    height: 33px;
    width: 100%;
  }
  .desc {
    font-size: 15px;
    color: #ffffff;
    opacity: 0.77;
    font-weight: 300;
    line-height: 22px;
    margin-top: 10px
  }

  @media screen and (max-width: 768px) {
    .desc {
      text-align: center;
    }
  }
`;

const Crypto = () => (
  <CryptoStyled>
    <div>
      <img src={cryptos} alt="MyConstant crypto" />
    </div>
    <p className="desc clearBottom">
      <LabelLang id="newHome.crypto.desc" />
    </p>
  </CryptoStyled>
);

export default Crypto;
