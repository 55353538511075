import React, {useState} from 'react';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {LabelLang, HtmlLang} from 'src/lang/components';
import BrowserDetect from 'src/services/browser-detect';

import data from './data';
import styles from './styles.scss';
import useCryptoInterest from 'src/hooks/useCryptoInterest';
import useInvestInterest from 'src/hooks/useInvestInterest';
import useBorrowInterest from 'src/hooks/useBorrowInterest';

const ProductsAndBenefits = () => {
  const [cryptoLendRate] = useCryptoInterest({apyRate: useSelector(state => state.app.contentVariables.crypto_lend_rate)});
  const [cryptoLendRateStable] = useCryptoInterest({});
  const [minTermRate] = useBorrowInterest({});
  const [maxTermRate] = useInvestInterest({});

  const updatedData = data({cryptoLendRate, cryptoLendRateStable, minTermRate, maxTermRate});
  const [viewAll, setViewAll] = useState(false);
  const [benefit, setBenefit] = useState(updatedData[0]);
  const items = viewAll || BrowserDetect.isMobile ? updatedData : updatedData.slice(0, 3);

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionTitle}><LabelLang id="productsAndBenefits.title"/></h2>
      <div className={styles.sectionDesc}><HtmlLang id="productsAndBenefits.desc" /></div>
      {BrowserDetect.isMobile ? (
        <>
          <div className={styles.benefitsContainer}>
            <div className={styles.benefits}>
              {items.map((item, i) => {
                return (
                  <div key={item.benefit} onClick={() => setBenefit(item)} className={cx(item.benefit === benefit.benefit ? 'selected' : '')}>{item.benefit}</div>
                );
              })}
            </div>
          </div>
          <div className={styles.sectionContent}>
            <div className={styles.benefitHeader}>
              <div className={cx(styles.product, styles.highlight, styles.subCategory)}><LabelLang id="productsAndBenefits.products" /></div>
              <div className={cx(styles.highlight, styles.subCategory)}>{benefit.benefit}</div>
            </div>
            <div className={styles.benefitItem}>
              <div className={styles.invest}><LabelLang id="productsAndBenefits.invest" /></div>
              <div className={styles.contents}>
                <div className={styles.title}><HtmlLang id="productsAndBenefits.flex" /></div><div className="content">{benefit.flex}</div>
                <div className={styles.title}><HtmlLang id="productsAndBenefits.cryptoBacked" /></div><div className="content odd" dangerouslySetInnerHTML={{ __html: benefit.cryptoBacked }} />
                <div className={styles.title}><HtmlLang id="productsAndBenefits.cryptoLend" /></div><div className="content">{benefit.cryptoLend}</div>
                <div className={styles.title}><HtmlLang id="productsAndBenefits.lendingLottery" /></div><div className="content odd">{benefit.lendingLottery}</div>
              </div>
            </div>
            <div className={cx(styles.benefitItem, styles.last)}>
              <div className={styles.borrow}><LabelLang id="productsAndBenefits.borrow" /></div>
              <div className={styles.contents}>
                <div className={styles.title}><HtmlLang id="productsAndBenefits.cryptoBackedLoan" /></div>
                <div className={styles.content} dangerouslySetInnerHTML={{__html: benefit.cryptoBackedLoan }} />
              </div>
            </div>
          </div>
        </>
      ) : (
          <>
            <div className={styles.sectionContent}>
              <div className={styles.benefitHeader}>
                <div className={cx(styles.category, styles.benefit)} />
                <div className={cx(styles.category, styles.invest)}><LabelLang id="productsAndBenefits.invest" /></div>
                <div className={cx(styles.category, styles.borrow)}><LabelLang id="productsAndBenefits.borrow" /></div>
              </div>
              <div className={styles.benefitHeader}>
                <div className={cx(styles.category, styles.benefit)}><div><HtmlLang id="productsAndBenefits.benefit" /></div></div>
                <div className={cx(styles.subCategory, styles.highlight)}><HtmlLang id="productsAndBenefits.flex" /></div>
                <div className={cx(styles.subCategory, styles.highlight)}><HtmlLang id="productsAndBenefits.cryptoBacked" /></div>
                <div className={cx(styles.subCategory, styles.highlight)}><HtmlLang id="productsAndBenefits.cryptoLend" /></div>
                <div className={cx(styles.subCategory, styles.highlight)}><HtmlLang id="productsAndBenefits.lendingLottery" /></div>
                <div className={cx(styles.subCategory, styles.highlight)}><HtmlLang id="productsAndBenefits.cryptoBackedLoan" /></div>
              </div>
              {
                items.map((item, i) => {
                  return (
                    <div key={item.benefit} className={cx(styles.benefitItem, i === items.length - 1 ? 'last' : '')}>
                      <div dangerouslySetInnerHTML={{__html: item.benefit }} className="benefit" />
                      <div dangerouslySetInnerHTML={{__html: item.flex }} />
                      <div dangerouslySetInnerHTML={{__html: item.cryptoBacked }} />
                      <div dangerouslySetInnerHTML={{__html: item.cryptoLend }} />
                      <div dangerouslySetInnerHTML={{__html: item.lendingLottery }} />
                      <div dangerouslySetInnerHTML={{__html: item.cryptoBackedLoan }} />
                    </div>
                  );
                })
              }
            </div>
            {!viewAll && (
              <div className={styles.sectionActions}>
                <Button onClick={() => setViewAll(true)}><LabelLang id="productsAndBenefits.viewAll" /></Button>
              </div>
            )}
          </>
      )}
    </div>
  );
};

export default ProductsAndBenefits;
