import React, { memo, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment-timezone/moment-timezone';
import { BigNumber } from 'bignumber.js';
import CountUp from 'react-countup';
import loadable from '@loadable/component';
import cx from 'classnames';

import { getMatchedBookings, getMatchStats } from 'src/components/statisticWidget/action';
import Loading from 'src/components/loading';
import { formatCurrencyByLocale } from 'src/utils/format/currency';
import { LabelLang } from 'src/lang/components';
import { DAYS_PER_MONTH, ONE_YEAR_SECONDS } from 'src/resources/constants/app';
import { fromNowRepleace } from 'src/utils/timeString';
import { getStatisticInvestment } from 'src/screens/statistic/action';
import styles from './styles.scss';

const MobileModal = loadable(() => import('src/components/mobileModal'));
const getIntlKey = name => `proLending.matchStats.${name}`;

const modal24High = 'high';
const modalVolume = 'volume';

const MobileLiveMarket = ({ getMatchedBookings }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState(null);
  const [modalType, setModalType] = useState('');
  const [statisticData, setStatisticData] = useState({});
  const [isLoadingStatisticData, setIsLoadingStatisticData] = useState(false);
  const [oldTotalInterestAmount, setOldTotalInterestAmount] = useState(0);
  const [totalInterestAmount, setTotalInterestAmount] = useState(0);
  const [timer, setTimer] = useState(null);
  const [flexInterest, setFlexInterest] = useState(0);
  const dispatch = useDispatch();

  const modal = useRef();

  useEffect(() => {
    getMatchedData();
    getStatisticData();
    getMatchedBookings().then(res => {
      setBookings(res?.length > 20 ? res.slice(0, 20) : res);
    });

    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if(statisticData) {
      initTimer();
      updateBalanceInterest();
    }
  }, [statisticData]);

  useEffect(() => {
    updateBalanceInterest();
  }, [flexInterest]);

  const initTimer = () => {
    clearTimer();
    const t = setInterval(calculateBalanceInterest, 3000);
    setTimer(t);
  };

  const clearTimer = () => {
    if(timer) {
      clearInterval(timer);
      setTimer(null);
    }
  };

  const getMatchedData = () => {
    setIsLoading(true);
    dispatch(getMatchStats()).then(res => {
      setData(res);
    }).catch(err => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getStatisticData = () => {
    setIsLoadingStatisticData(true);
    dispatch(getStatisticInvestment()).then(obj => {
      setStatisticData(obj);
    }).catch(err => console.log(err))
      .finally(() => {
        setIsLoadingStatisticData(false);
      });
  };

  const updateBalanceInterest = () => {
    setOldTotalInterestAmount(totalInterestAmount);
    const interestAmount = Number(statisticData?.totalInterestAmount || 0);
    const newInterestAmount = Number(statisticData?.newTotalInterestAmount || 0);
    const newTotalInterestAmount = new BigNumber(flexInterest).plus(interestAmount).plus(newInterestAmount).decimalPlaces(3, BigNumber.ROUND_DOWN).toNumber() || 0;
    setTotalInterestAmount(newTotalInterestAmount);
  };

  const calculateBalanceInterest = () => {
    const { totalBalance, updatedAt } = statisticData;
    const currentInterestRate = 4;

    const diffTime = moment.utc().diff(updatedAt, 'second') || 0;
    const rateYear = new BigNumber(currentInterestRate).dividedBy(new BigNumber(100)).dividedBy(new BigNumber(ONE_YEAR_SECONDS));
    const rate = new BigNumber(1).plus(rateYear);
    const interestBalance = new BigNumber(totalBalance).multipliedBy(Math.pow(rate.toNumber(), diffTime)).minus(totalBalance);

    setFlexInterest(interestBalance);
  };

  const showMarketDetail = type => {
    setModalType(type);
    modal.current?.open();
  };

  const renderContent = () => {
    let view = <span />;
    if (modalType === modalVolume) {
      view = (
        <div className={styles.modalVolume}>
          <div>
            <label>Amount (USD)</label>
          </div>
          <div style={{ textAlign: 'center' }}>
            <label>Interest (APR)</label>
          </div>
          <div style={{ textAlign: 'center' }}>
            <label>Term (Days)</label>
          </div>
          {/* <div style={{ textAlign: 'center' }}>
            <label>User type</label>
          </div> */}
          <div style={{ textAlign: 'right' }}>
            <label>Time ago</label>
          </div>
          {bookings?.map((item, i) => (
            <React.Fragment key={item}>
              <div>
                <span>
                  {formatCurrencyByLocale(item?.balance || item?.amount)}
                </span>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>{Number(item?.rate).toFixed(2)}%</span>
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>{item?.days}</span>
              </div>
              {/* <div style={{ textAlign: 'center' }}>
                <LabelLang
                  id={`memberShip.memberShipLevel.level${item?.membership}`}
                />
              </div> */}
              <div style={{ textAlign: 'right' }}>
                <span>
                  {fromNowRepleace(moment(item?.createdAt).fromNow(true))}
                </span>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    } else if (modalType === modal24High) {
      view = (
        <div className={styles.modal24hHigh}>
          <div>
            <label>Amount (USD)</label>
            <div>
              {formatCurrencyByLocale(data?.maxRateDetail?.amount || '0')}
            </div>
          </div>
          <div>
            <label>Interest (APR)</label>
            <div>
              {formatCurrencyByLocale(data?.maxRateDetail?.rate || '0')}%
            </div>
          </div>
          <div>
            <label>Term (Days)</label>
            <div>
              {(Number(data?.maxRateDetail?.term) * DAYS_PER_MONTH).toFixed(0)}{' '}
              days
            </div>
          </div>
          <div>
            <label>Market</label>
            <div>
              {data?.maxRateDetail?.type === 'void' ? 'Secondary' : 'Primary'}
            </div>
          </div>
          {/* <div>
            <label>User type</label>
            <div>
              <LabelLang
                id={`memberShip.memberShipLevel.level${
                  data?.maxRateDetail?.membership
                }`}
              />
            </div>
          </div> */}
          <div className="time">
            <label>Time</label>
            <div>
              {moment(data?.maxRateDetail?.createdAt).format('MMM DD, HH:mm')}
            </div>
          </div>
        </div>
      );
    }
    return view;
  };

  return (
    <React.Fragment>
      <div className={styles.liveMarket}>
        <div className={styles.badgeLive}>LIVE</div>

        <div
          className={cx(styles.volume, Boolean(isLoading || data?.maxRate) && styles.isShow)}
          onClick={() => showMarketDetail(modal24High)}
        >
          <div className={styles.volumeValue}>
            {isLoading ? (
              <Loading dark={false} />
            ) : (
              `${data?.maxRate}%`
            )}
          </div>
          <div className={styles.volumeLabel}>
            <LabelLang id={getIntlKey('high')} />
          </div>
        </div>

        {data?.volume && data?.maxRate && <div className={styles.volumeLine} />}

        <div
          className={cx(styles.volume, Boolean(isLoadingStatisticData || statisticData?.totalInterestAmount) && styles.isShow)}
          onClick={() => showMarketDetail(modalVolume)}
        >
          <div className={cx(styles.volumeValue, styles.interest)}>
            {isLoadingStatisticData ? (
              <Loading dark={false} />
            ) : (
              <CountUp prefix='$' separator=',' start={oldTotalInterestAmount} end={totalInterestAmount} decimals={3} duration={3} useEasing={false} />
            )}
          </div>
          <div className={styles.volumeLabel}>
            <LabelLang id={getIntlKey('totalInterest')} />
          </div>
        </div>
      </div>
      <MobileModal ref={modal} title={getIntlKey(modalType)}>
        {renderContent()}
      </MobileModal>
    </React.Fragment>
  );
};

export default connect(
  null,
  { getMatchedBookings },
)(memo(MobileLiveMarket));
