import React, { useEffect } from 'react';
import cx from 'classnames';

import LabelLang from 'src/lang/components/LabelLang';
import GlossaryLang from 'src/lang/components/GlossaryLang';
import BlockSection from 'src/components/blocksection';
import { loadLazyImage } from 'src/utils/images';

import BgHowItWork from './img/bg_how.png';
import styles from './styles.scss';

const getIntlKey = key => `newHome.howItWorks.${key}`;

const HowItWorks = () => {
  useEffect(() => {
    loadLazyImage();
  }, []);

  return (
    <BlockSection className={styles.howItWork} containerClassName={styles.container}>
      <img className={cx(styles.picture, 'lazy-image')} alt="How does MyConstant P2P lending platform works" data-src={BgHowItWork} src="" />
      <div style={{ position: 'relative' }}>
        <h2 className={styles.title}><LabelLang id={getIntlKey('title')} /></h2>
        <div className={styles.desc}><GlossaryLang id={getIntlKey('desc')} /></div>
        <div className={styles.list}>
          {[0, 1].map(i => {
            return (
              <div key={i} className={styles.item}>
                <label><LabelLang id={getIntlKey(`data.${i}.title`)} /></label>
                <GlossaryLang id={getIntlKey(`data.${i}.desc`)} />
              </div>
            );
          })}
        </div>
      </div>
    </BlockSection>
  );
};

export default HowItWorks;
