import React from 'react';
import styled from 'styled-components';
import BlockSection from './index';

const BlockSectionHeaderStyled = styled.div`
  .container {
    padding-top: 0;
    max-width: 1440px;
  }
`;

const BlockSectionHeader = (props) => {
  return (
    <BlockSectionHeaderStyled>
      <BlockSection {...props} />
    </BlockSectionHeaderStyled>
  );
};

export default BlockSectionHeader;
