import React, { useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';
import LabelLang from 'src/lang/components/LabelLang';
import bg1 from 'src/assets/v1/howTo/bg1.svg';
import bg2 from 'src/assets/v1/howTo/bg2.svg';
import bg3 from 'src/assets/v1/howTo/bg3.svg';
import bg4 from 'src/assets/v1/howTo/bg4.svg';
import bg5 from 'src/assets/v1/howTo/bg5.svg';
import GlossaryLang from 'src/lang/components/GlossaryLang';
import BrowserDetect from 'src/services/browser-detect';
import { loadLazyImage } from 'src/utils/images';

import pic1 from './img/reliable_return.svg';
import pic2 from './img/no_credit_check.svg';
import pic3 from './img/free_withdrawal.svg';
import pic4 from './img/flexible_term.svg';
import pic5 from './img/cs_support.svg';
import pic6 from './img/cashback.svg';
import styles from './styles.scss';

const IMAGES = [
  {
    img: pic1,
    bg: bg1,
    alt: 'Why invest in MyConstant - Reliable returns'
  },
  {
    img: pic3,
    bg: bg2,
    alt: 'Why invest in MyConstant - Free withdrawals'
  },
  {
    img: pic4,
    bg: bg3,
    alt: 'Why invest in MyConstant - Flexible terms'
  },
  {
    img: pic5,
    bg: bg4,
    alt: 'Why invest in MyConstant - 24/7 customer support'
  },
  {
    img: pic2,
    bg: bg5,
    alt: 'Why invest in MyConstant - No credit checks',
  },
  {
    img: pic6,
    bg: bg5,
    alt: 'Why invest in MyConstant - No early repayment fees',
  },
];

const data = IMAGES.map((e, i) => ({
  title: <LabelLang id={`newHome.whyItBetter.data.${i}.title`} />,
  desc: <GlossaryLang id={`newHome.whyItBetter.data.${i}.desc`} />,
  ...e
}));

const WhyItBetter = () => {
  useEffect(() => {
    loadLazyImage();
  }, []);

  return (
    <div className={styles.whyContainer}>
      <Row>
        <Col md={4}>
          <h2 className={styles.sectionTitle}><LabelLang id="newHome.whyItBetter.title" /></h2>
          <div className={styles.sectionDesc}>Unlike traditional P2P platforms that prey on vulnerable borrowers and expect investors to shoulder the risk, <>{BrowserDetect.isDesktop && <br />}</>MyConstant is designed to protect them both.</div>
        </Col>
        <Col md={8}>
          <div className={styles.sectionContent}>
            {data.map((e, i) => (
              <div key={i} className={styles.whyItem}>
                <img className="lazy-image" alt={e.alt} data-src={e.img} />
                <div>
                  <label>{e.title}</label>
                  <div>{e.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WhyItBetter;
