import React from 'react';

const TrustPilotWidget = ({theme = 'dark'}) => {
  return (
    <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5dc127cb949f780001dd87e2" data-style-height="24px" data-style-width="100%" data-theme={theme} data-style-alignment="center">
      <a href="https://www.trustpilot.com/review/myconstant.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  )
};

export default TrustPilotWidget;