import React, {useState} from 'react';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import play from './play.svg';

const YoutubePopupStyled = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  font-size: 12px;
  color: #FFFFFF;
  font-family: SFMono;
  font-weight: 300; 

  img {
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }
  
  margin: 20px 0;
  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
`;

const YoutubePopup = ({ text = '', videoId, img, handleClick, callback, ...restProps }) => {
  const [open, setOpen] = useState(false);
  return (
    <YoutubePopupStyled
      color="#ffffff"
      bg="#149CFF"
      role="presentation"
      onClick={() => {
        if(videoId) {
          setOpen(true);
        } else {
          handleClick();
        }

        callback && callback();
      }}
      {...restProps}>
        <img src={img ? img : play} />
        {text && <div>{text}</div>}
        {__CLIENT__ && videoId && <ModalVideo channel='youtube' youtube={{ autoplay: 1, mute: 1 }} isOpen={open} videoId={videoId} onClose={() => setTimeout(() => setOpen(false), 100)}/>}
    </YoutubePopupStyled>
  );
};

export default YoutubePopup;
