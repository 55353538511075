import React from 'react';
import styled from 'styled-components';
import HtmlLang from 'src/lang/components/HtmlLang';
import LabelLang from 'src/lang/components/LabelLang';
import { Button } from 'react-bootstrap';
import bgDefault from 'src/screens/yourAccounts/img/deposit.svg';
import bgNetIncome from './assets/bgInvest.png';
import bgExpense from './assets/bgLendCrypto.png';
import bgIncome from './assets/bgBorrow.png';

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 49vw;
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0 10px 20px 0 rgba(16,17,51,0.18);
  border-radius: 15px;
  background-image: url(${bgDefault}), url(${bgNetIncome});
  background-repeat: no-repeat;
  background-position-x: right, right;
  background-position-y: top, top;
  background-size: auto, contain;
  .subTitle {
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.83px;
    line-height: 22px;
    text-transform: uppercase;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .interestRate-label {
    font-size: 12px;
    color: #788498;
  }
  .interestRate-value {
    font-size: 20px;
    color: #FFAA00;
    font-weight: 700;
  }
  .desc {
    font-size: 12px;
    color: #232323;
    line-height: 16px;
  }
  .btn-explore {
    background-image: linear-gradient(-11deg, invalid gradient);
    border-radius: 10px;
    height: 45px;
    width: 100%;
    margin-top: 10px;

    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 600;
  }
  &.primary {
    .subTitle {
      color: #0D64E2;
    }
  }
  &.success {
    left: calc(49vw - 10px);
    top: 70px;
    background-image: url(${bgDefault}), url(${bgIncome});
    .subTitle {
      color: #149F4E;
    }
    .interestRate-value {
      color: #18AA54;
    }
    .btn-explore {
      background-image: linear-gradient(-38deg, #11994A 0%, #1EB65B 100%);
    }
  }
  &.warning {
    top: 210px;
    left: 50px;
    background-image: url(${bgDefault}), url(${bgExpense});
    .subTitle {
      color: #CFB000;
    }
    .interestRate-value {
      color: #FF8500;
    }
    .btn-explore {
      background-image: linear-gradient(-44deg, #FFA900 0%, #FF9E00 100%);
    }
  }
`;

const MobileHeaderLOCard = ({
  title,
  interestRate,
  desc,
  variant,
  subtitle,
  onPress
}) => {
  return (
    <StyledContainer className={variant}>
      <div className='subTitle'><HtmlLang id={subtitle} /></div>
      <div className='title'><HtmlLang id={title} /></div>
      <div className='interestRate-label'><LabelLang id='mobile_new_home.lb_interest_rate' /></div>
      <div className='interestRate-value'>{interestRate}</div>
      <div className='desc'>{desc}</div>
      <Button className='btn-explore' onClick={onPress}>
        <LabelLang id='newHome.banner.explore'/>
      </Button>
    </StyledContainer>
  );
};


export default MobileHeaderLOCard;
