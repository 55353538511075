import React, { memo, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import cx from 'classnames';

import MobileHeaderLOCard from 'src/components/mobileHeaderLoCard/index.mobile';
import YoutubePopup from 'src/components/YoutubePopup';
import { HtmlLang, LabelLang } from 'src/lang/components';
import { URL } from 'src/resources/constants/url';
import { roundNumCollateral } from 'src/utils/common';
import Cryptos from '../../cryptos';

import MobileLiveMarket from './liveMarket';
import styles from './styles.scss';
import useCryptoInterest from 'src/hooks/useCryptoInterest';
import useInvestInterest from 'src/hooks/useInvestInterest';
import useBorrowInterest from 'src/hooks/useBorrowInterest';

const getIntlKey = name => `newHome.banner.${name}`;

const MobileBanner = ({ history, contentVariables }) => {
  const [cryptoLendInterestStable] = useCryptoInterest({});
  const [maxInvestInterest] = useInvestInterest({});
  const [minBorrowInterest] = useBorrowInterest({});
  const [showMore, setShowMore] = useState(false);

  const onShowMore = () => {
    setShowMore(value => !value);
  };

  return (
    <div className={styles.bannerContainer}>
      <h1 className={styles.bannerTitle}>
        <HtmlLang id={getIntlKey('title')} />
      </h1>
      <div
        role="presentation"
        className={cx(styles.bannerDesc, showMore && styles.expand)}
        onClick={showMore ? onShowMore : undefined}
      >
        <HtmlLang
          id={getIntlKey('subTitle')}
          values={{
            investRate: maxInvestInterest,
            borrowRate: minBorrowInterest,
            numCollateral: roundNumCollateral(contentVariables?.cryptos_as_collateral_number),
          }}
        />
        {!showMore && <div role="presentation" className={styles.showMore} onClick={onShowMore} />}
      </div>
      <div className={styles.bannerLoWrap}>
        <div className={styles.bannerTopRow}>
          <MobileHeaderLOCard
            variant='primary'
            subtitle={getIntlKey('invest.header')}
            title={getIntlKey('invest.title')}
            desc={<HtmlLang id={getIntlKey('invest.desc')} />} 
            interestRate={`Up to ${maxInvestInterest}%`}
            onPress={() => history.push(URL.INVEST)}
          />
          <MobileHeaderLOCard
            variant='success'
            subtitle={getIntlKey('borrow.header')}
            title={getIntlKey('borrow.title')}
            desc={<HtmlLang id={getIntlKey('borrow.desc')} values={{ numCollateral: roundNumCollateral(contentVariables.cryptos_as_collateral_number) }} />}
            interestRate={`From ${minBorrowInterest}%`}
            onPress={() => history.push(URL.CRYPTO_LOAN)}
            // numCollateral={roundNumCollateral(contentVariables.cryptos_as_collateral_number)}
          />
        </div>
        <div className={styles.bannerSecondRow}>
          <MobileHeaderLOCard
            variant='warning'
            subtitle={getIntlKey('lendCrypto.header')}
            title={getIntlKey('lendCrypto.title')}
            desc={<HtmlLang id={getIntlKey('lendCrypto.desc')} values={{ rate: cryptoLendInterestStable }} />}
            interestRate={`Up to ${cryptoLendInterestStable}%`}
            onPress={() => history.push(URL.FLEX_CRYPTO)}
          />
        </div>
      </div>
      <div className={styles.youtubePopup}>
        <YoutubePopup
          text={<LabelLang id={getIntlKey('intro.youtube.title')} />}
          videoId="teCHLi6X3wc"
          className='mb-ytb'
        />
      </div>
      <Cryptos />
      <MobileLiveMarket />
    </div>
  );
};

const mapState = state => ({
  contentVariables: state.app.contentVariables,
});

export default withRouter(connect(mapState, null)(MobileBanner));
