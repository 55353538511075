import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import BalanceInfo from './balanceInfo';
import styles from './styles.scss';

const MyCard = ({ isAuth, component, estimatedValue, reward, className, ...restProps }) => {
  return (
    <div className={cx(styles.card)}>
      <div className={styles.bg} />
      <div className={cx(styles.myCard)}>
        <BalanceInfo reward={reward} />
      </div>
    </div>
  );
};

const mapState = state => ({
  isAuth: !!state.auth?.token,
});

const mapDispatch = {
};

export default connect(mapState, mapDispatch)(MyCard);
