import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import loadable from '@loadable/component';

import logoDark from 'src/assets/images/logo-dark.svg';
import { HtmlLang, LabelLang } from 'src/lang/components';
import GlossaryLang from 'src/lang/components/GlossaryLang';
import { URL } from 'src/resources/constants/url';
import BrowserDetect from 'src/services/browser-detect';
import LocalStore from 'src/services/localStore';
import BlockSectionHeader from 'src/components/blocksection/header';
import { roundNumCollateral } from 'src/utils/common';

import BalanceCard from './balanceCard';
import BannerCard from './bannerCard';
import newImg from './img/newImg.svg';
import viewMoreBg from './img/viewMore.svg';
import MobileBanner from './mobile';
import Cryptos from '../cryptos';
import styles from './styles.scss';
import useCryptoInterest from 'src/hooks/useCryptoInterest';
import useInvestInterest from 'src/hooks/useInvestInterest';
import useBorrowInterest from 'src/hooks/useBorrowInterest';
import base from './img/base.webp';
import gold from './img/gold.webp';
import platinum from './img/platinum.webp';
import diamond from './img/diamond.webp';
import TrustPilotWidget from 'src/components/trustPilot/widget';

const IMAGES = [base, gold, platinum, diamond];

const StatisticWidget = loadable(() => import('src/components/statisticWidget'));
const YoutubePopup = loadable(() => import('src/components/YoutubePopup'));

const getIntlKey = name => `newHome.banner.${name}`;

const Banner = ({ history }) => {
  const [cryptoLendInterestStable] = useCryptoInterest({});
  const [maxInvestInterest] = useInvestInterest({});
  const [minBorrowInterest] = useBorrowInterest({});
  const [viewMore, setViewMore] = useState(BrowserDetect.isDesktop);
  const isApp = LocalStore.get('LOAD_WEBVIEW') || false;

  const isAuth = useSelector(state => !!state.auth?.token);
  const contentVariables = useSelector(state => state.app.contentVariables);
  const loyaltyBenefits = useSelector(state => state?.auth?.loyaltyBenefits);
  const websiteMobile = IMAGES[loyaltyBenefits?.Benefits?.Level || 0];

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  return BrowserDetect.isMobile ? (
    <MobileBanner contentVariables={contentVariables} />
  ) : (
    <BlockSectionHeader fixed className={cx(styles.bannerContainer)}>
      <Row>
        <Col md={5} className={styles.description}>
          <div style={{ width: '85%' }}>
            <div>
              <img src={logoDark} alt="MyConstant alternative logo" className={styles.logo} />
              <div className={styles.newMessage}>
                <YoutubePopup
                  text={<LabelLang id={getIntlKey('newMessage')} />}
                  videoId="Jtp2Oz3vf-A"
                  img={newImg}
                />
              </div>
              <h1 style={{ maxWidth: '480px' }} className="crowdin">
                <HtmlLang id={getIntlKey('title')} />
              </h1>
              <div className={styles.subTitle}>
                <GlossaryLang
                  id={getIntlKey('subTitle')}
                  values={{
                    investRate: maxInvestInterest,
                    borrowRate: minBorrowInterest,
                    numCollateral: roundNumCollateral(contentVariables.cryptos_as_collateral_number),
                  }}
                />
                <img src={viewMoreBg} alt="view More" className={styles.viewMoreBg} onClick={handleViewMore} />
                <label onClick={handleViewMore}>
                  {viewMore ? '' : <LabelLang id={getIntlKey('viewMore')} />}
                </label>
              </div>
            </div>
            <div className={styles.youtubePopup}>
              <YoutubePopup
                text={<LabelLang id={getIntlKey('intro.youtube.title')} />}
                videoId="teCHLi6X3wc"
                className={styles.bannerYoutubeBtn}
              />
            </div>
            <Cryptos />
            <TrustPilotWidget />
          </div>
        </Col>
        <Col md={4} className={styles.banner}>
          <div className={styles.bannerCards}>
            <div>
              <BannerCard
                type="invest"
                rate={`Up to ${maxInvestInterest}%`}
                term='1 - 9 Months'
                subtitle={<LabelLang id={getIntlKey('invest.header')} />}
                title={<LabelLang id={getIntlKey('invest.title')} />}
                desc={(
                  <HtmlLang
                    id={getIntlKey('invest.desc')}
                    values={{ rate: maxInvestInterest }}
                  />
                )}
                callback={isApp ? () => {} : () => history.push(URL.INVEST)}
              />
              <BannerCard
                type="lendCrypto"
                rate={`Up to ${cryptoLendInterestStable}%`}
                term='Flexible'
                subtitle={<LabelLang id={getIntlKey('lendCrypto.header')} />}
                title={<LabelLang id={getIntlKey('lendCrypto.title')} />}
                desc={(
                  <HtmlLang
                    id={getIntlKey('lendCrypto.desc')}
                    values={{ rate: cryptoLendInterestStable }}
                  />
                )}
                callback={isApp ? () => {} : () => history.push(URL.FLEX_CRYPTO)}
              />
            </div>
            <div>
              <BannerCard
                type="borrow"
                rate={`From ${minBorrowInterest}%`}
                term='1 - 9 Months'
                subtitle={<LabelLang id={getIntlKey('borrow.header')} />}
                title={<LabelLang id={getIntlKey('borrow.title')} />}
                desc={(
                  <HtmlLang
                    id={getIntlKey('borrow.desc')}
                    values={{
                      rate: minBorrowInterest,
                      numCollateral: roundNumCollateral(contentVariables.cryptos_as_collateral_number),
                    }}
                  />
                )}
                callback={
                  isApp ? () => {} : () => history.push(URL.CRYPTO_LOAN)
                }
              />
            </div>
          </div>
        </Col>
        <Col md={3} className={styles.statistic}>
          <StatisticWidget className={styles.statisticWidget} />
          <div className={styles.phone}>
            <img src={websiteMobile} alt="MyConstant app view" />
            {/*<img src={websiteMobileShadow} alt="MyConstant app view" className="shadow"/>*/}
            {isAuth && <BalanceCard />}
          </div>
        </Col>
      </Row>
    </BlockSectionHeader>
  );
};

export default withRouter(Banner);
