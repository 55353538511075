import React, { useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';

import LabelLang from 'src/lang/components/LabelLang';
import { getInfoMembership } from 'src/screens/memberShip/action';
import { URL } from 'src/resources/constants/url';
import BalanceInfoPopover from 'src/components/balanceInfoPopover';
import separator from 'src/screens/meActions/img/cards/separator.png';

import styles from './styles.scss';
import { convertTo10e8, formatCollateralDisplayShorten } from 'src/components/exchangeMethods/loan/utils';

const FlexAvailableBalance = loadable(() => import('src/components/flexAvailableBalance'));

const getIntlKey = (key) => `user.account.${key}`;

const BalanceInfo = ({ systemInterest, history }) => {
  const target = useRef(null);
  const loyaltyBenefits = useSelector(state => state.auth?.loyaltyBenefits);

  const viewMembershipInfo = () => {
    history.push(URL.MEMBERSHIP);
  };

  return (
    <div className={styles.balanceInfo}>
      <div className={styles.column}>
        <div>
          <span className={styles.balanceTitle}>
            <LabelLang id={getIntlKey('currentBalance')} />
            <BalanceInfoPopover includeStablecoin />
          </span>
          <span className={styles.flexInterest}> {systemInterest}% APY</span>
        </div>
        <div className={styles.balanceAmount} ref={target}><FlexAvailableBalance decimal={5} includeTrial /></div>
        <div>
          <span className={styles.balanceTitle}><LabelLang id={getIntlKey('totalBalance')} /></span>
          <span className={styles.totalAmount}> <FlexAvailableBalance includeTrial isFix /></span>
        </div>
      </div>
      <img alt="" src={separator} />
      <div role="presentation" className={styles.column} onClick={viewMembershipInfo}>
        <div><span className={styles.balanceTitle}>{loyaltyBenefits?.Benefits?.Name} <LabelLang id={getIntlKey('member')} /></span></div>
        <div className={styles.balanceAmount}><strong>{formatCollateralDisplayShorten(convertTo10e8(loyaltyBenefits?.MCTLockBalance || 0))}</strong> MCT</div>
        <div><span className={styles.memberShipReward}><LabelLang id={getIntlKey('membership')} /></span></div>
      </div>
    </div>
  );
};

const mapState = state => ({
  profile: state.auth.profile,
  systemInterest: state.auth.systemInterest,
});

const mapDispatch = {
  getInfoMembership
};

export default withRouter(connect(mapState, mapDispatch)(BalanceInfo));
