import React from 'react';
import cx from 'classnames';
import LabelLang from 'src/lang/components/LabelLang';

import { Button, Image } from 'react-bootstrap';
import styles from './styles.scss';
import ThreeDotIcon from '../img/threeDot.svg';

const BannerCard = ({ subtitle, title, desc, callback, type, rate, term }) => (
  <div className={cx(styles.cardContainer, styles[type])}>
    <div className={styles.header}>
      <Image src={ThreeDotIcon} />
    </div>
    <div className={styles.content}>
      <div className={styles.titleView}>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.contentRow}>
        <div className={styles.rateCol}>
          <div className={styles.rateValue}>
            <div className={styles.rateLabel}><LabelLang id="newHome.banner.rate" /></div>
            {rate}
          </div>
        </div>
        <div className={styles.termCol}>
          <div className={styles.contentLabel}><LabelLang id="newHome.banner.term" /></div>
          <div className={styles.termValue}>{term}</div>
        </div>
      </div>
      <div className={styles.desc}>{desc}</div>
      <Button onClick={() => callback()}><LabelLang id="newHome.banner.explore" /></Button>
    </div>
  </div>
);

export default BannerCard;
