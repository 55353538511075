import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import Blocksection from 'src/components/blocksection';
import browserDetect from 'src/services/browser-detect';

import Banner from './banner';
import Statistic from './statistic';
import HowItWorks from './howItWorks';
import WhyItBetter from './whyItBetter';
import ProductsAndBenefits from './productsAndBenefits';
import Faqs from './faqs';
import styles from './styles.scss';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { trackIpInfo } from 'src/services/ga/ga-utils';
import { events } from 'src/services/ga';
import { useDispatch } from 'react-redux';
import { SOCIAL } from 'src/resources/constants/url';

// const Banner = loadable(() => import('./banner'), { ssr: false });
const MyHelmet = loadable(() => import('src/components/myHelmet'));
const BreadcrumRichSnippet = loadable(() =>
  import('src/components/breadcrumRichSnippet'),
);
const OurPress = loadable(() => import('src/components/ourPress'));
const TrustPilot = loadable(() => import('src/components/trustPilot'));

const NewHome = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.search) {
      const queryParams = queryString.parse(location?.search);
      if (queryParams?.type === 'download_app') {
        dispatch(trackIpInfo(events.category.downloadApp, events.action.downloadAppQrCode));
        window.location.href = SOCIAL.QR_CODE_DOWNLOAD;
      }
    }
  }, [location.search]);

  return (
    <div>
      <MyHelmet page="homepage" />
      <Banner />
      {/* {!browserDetect.isMobile && <Statistic />} */}
      <Blocksection className={styles.whySection}>
        <WhyItBetter />
        <ProductsAndBenefits />
      </Blocksection>
      <HowItWorks />
      <OurPress />
      <Faqs />
      <BreadcrumRichSnippet />
    </div>
  );
};

export default NewHome;
